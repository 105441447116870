import axios from 'axios';
import qs from 'qs';
import { Toast } from 'vant';
// 引入安卓js
import H5Object from './android';
import store from '@/store';
// import { formatParams } from '@/utils/index'
import i18n from '@/i18n';
let baseURL = '';
const isAndirodData = store.state.settings.isAndirodData;
let time = '';
const service = axios.create({});
let ToastLoading = '';
if (process.env.NODE_ENV === 'development') {
    // 测试环境
    baseURL = '/api';
    service.defaults.baseURL = baseURL;
}
// POST传参序列化(添加请求拦截器)
service.interceptors.request.use((config) => {
    const andirodData = store.state.settings.andirodData;
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    config.url = `${andirodData.ureduduu || ''}${config.url}`;
    // config.url = `http://ph-lendako-api-test.alltest.top${config.url}`
    time = String(Date.parse(String(new Date())));
    time = time.substring(0, 10);
    const lgObj = JSON.parse(localStorage.getItem('isEn') || '{}');
    let baseData = {
        Icitundu: 'en',
        token: 'aff053f26948b04aef27d9527929f9ac',
        Imimonekele_kulanga: '9503',
        Ubwafwilisho_Application: '1.0.0',
        Umusango_atemwa_Ibumba: '1',
        Ukulingana_inkalata: '737016C8-5E7C-4748-8073-00E013F0BC58',
        app_child_type: '1',
        app_name: 'CashAgad',
        Ama_Application_channel: '1',
        Loan_channel: '5',
        Ifishibisho_computer: 'CashAgad',
        promotion_channel: 'CashAgad',
        timestamp: time
    };
    // if (process.env.NODE_ENV !== 'development') Object.keys(baseData).forEach(key => { baseData[key] = '' })
    if (isAndirodData) {
        baseData = { ...baseData, timestamp: time, ...andirodData };
        // baseData.Icitundu = lgObj.val
    }
    config.data = { ...baseData, ...config.parmsdata };
    // config.data = formatParams(config.data)
    ToastLoading = Toast.loading({
        duration: 0,
        message: i18n.t('public.loading') + '',
        forbidClick: true
    });
    if (config.method === 'post') {
        config.data = qs.stringify(config.data);
    }
    if (config.method === 'get') {
        config.params = config.data;
    }
    return config;
}, (error) => {
    console.log('错误的传参');
    return Promise.reject(error);
});
// 返回状态判断(添加响应拦截器)
service.interceptors.response.use((response) => {
    const res = response.data;
    if (ToastLoading) {
        ToastLoading.clear();
    }
    if (res.inyimbo || res.daHIOCta) {
        if (((res.inyimbo && res.inyimbo + '') || (res.coHIOCde && res.coHIOCde + '')) !== '200') {
            Toast({ message: (res.msg_Ilyashi || res.msHIOCg) || '' });
            if ((res.inyimbo || res.coHIOCde) == '503') {
                console.log('走了你');
                H5Object.loginOutFiveZeroThree();
            }
            if ((res.inyimbo || res.coHIOCde) == '506') {
                return res;
            } // 发短信需要验证码了
            res.data = res.data_ilyashi;
            return Promise.reject(res);
        }
        else {
            // res.data = formatDatas(res.data)
            // console.log(res)
            res.data = res.data_ilyashi;
            return res;
        }
    }
    else {
        return response;
    }
}, (error) => {
    console.log('网络异常');
    Toast({
        message: i18n.t('public.netWorkErr') + ''
    });
    return Promise.reject(error);
});
export default service;
// eslint-disable-next-line no-undef
export function requestData(url, parmsdata, method, responseType) {
    return service({
        url,
        method: method || 'post',
        parmsdata,
        responseType: responseType || ''
    });
}
