import { requestData } from '@/api';
// 登录注册
export const checkMobile = (parms) => requestData('/isRegist', parms); // 检测手机号是否注册
export const customeRegistr = (parms) => requestData('/register', parms); // 注册
export const setPwd = (parms) => requestData('/setPwd', parms); // 设置密码
// 发送验证码/语音验证码
export const sendSms = async (parms, isValidateImg) => {
    console.log(parms, isValidateImg);
    // try {
    //   const data:any = await requestData('/sendSms', parms)
    //   if (data && data.code + '' === '200') {
    //     return { data: 'isSend' }
    //   }
    //   if (data && data.code + '' === '506') {
    //     return { data: '1' }
    //   }
    // } catch (err) {
    //   const error:any = err
    //   if (error.code + '' === '502') {
    //     return { data: '1', msg: error.msg }
    //   }
    // }
    if (isValidateImg) {
        const data = await requestData('/sendSms', parms);
        if (data.code + '200')
            return { data: 'isSend' };
    }
    else {
        return isNeedCaptcha(parms).then(async ({ data }) => {
            if (!data.Ukukusha) {
                const data = await requestData('/sendSms', parms);
                if (data.code + '200')
                    return { data: 'isSend' };
            }
            else {
                return { data: '1' };
            }
        });
    }
};
export const checkNewsCode = (parms) => requestData('/checkSms', parms); // 校验验证码
export const isNeedCaptcha = (parms) => requestData('/isNeedCaptcha', parms); // 检验是否需要图形验证
export const getCaptcha = (parms) => requestData('/getCaptcha', parms, 'post', 'arraybuffer').then(data => {
    const datas = data && data.data;
    return 'data:image/png;base64,' + btoa(new Uint8Array(datas).reduce((data, byte) => data + String.fromCharCode(byte), ''));
}); // 获取图形验证
export const checkCaptcha = (parms) => requestData('/checkCaptcha', parms); // 检验图形验证
export const userLogin = (parms) => requestData('/login', parms); // 登录/注册
export const codeLogin = (parms) => requestData('/login', parms); // 验证码登录
export const forgetPwd = (parms) => requestData('/setPwd', parms); // 忘记密码
export const getBasicInfo = (parms) => requestData('/getBaseInfo', parms); // 获取基本信息
export const getSubBasicInfo = (parms) => requestData('/baseInfoSubmit', parms); // 提交基本信息
export const getBasiccontPage = (parms) => requestData('/basiccontPage', parms); // 获取基本信息
export const getSubContactsInfo = (parms) => requestData('/basicContacts', parms); // 提交联系人信息
export const getbankPage = (parms) => requestData('/bankPage', parms); // 获取银行列表
export const getBankData = (parms) => requestData('/getBank', parms); // 获取银行信息
export const getSubBankInfo = (parms) => requestData('/bankSubmit', parms); // 提交银行信息
export const enchashmentWaySubmit = (parms) => requestData('/bankSubmit', parms); // 提交提现方式
export const getProvince = (parms) => requestData('/province', parms); // 获取省的名称
export const getCity = (parms) => requestData('/city', parms); // 获取市/县的名称
export const getCounty = (parms) => requestData('/county', parms); // 获取市/县的名称
export const getHomePage = (parms) => requestData('/homePage', parms); // 首页接口
export const getLoanAgreement = (parms) => requestData('/loanAgreement', parms); // 借款协议
// 个人中心
export const getUserInfo = (params) => requestData('/myInfo', params); // 查询用户信息
export const getbillList = (params) => requestData('/historyOrderList', params); // 账单列表还款记录
export const getNewsList = (parms) => requestData('/newsList', parms); // 消息列表
export const setNewsRead = (parms) => requestData('/newsDetail', parms); // 设置消息为已读(后台接口名字写错了也不改)
export const newsAllRead = (parms) => requestData('/allRead', parms); // 消息详情
export const getFeedback = (parms) => requestData('/pushFeedback', parms); // 问题反馈
export const subFeedback = (parms) => requestData('/question', parms); // 问题反馈提交
export const getMyTicket = (parms) => requestData('/myTicket', parms); // 我的券
export const getCouponList = (parms) => requestData('/couponList', parms); // 抵金券
export const getUpdatepwd = (parms) => requestData('/modifyPwd', parms); // 修改密码
export const getCouponExplain = (parms) => requestData('/buyTicket', parms); // 优惠券使用说明
export const cancelAccount = (parms) => requestData('/logOffAccount', parms); // 注销账号
// 待还款
export const getPartnersList = (params) => requestData('/getPartnersList', params); // 获取当前项目支持的三方
export const getStayPayList = (params) => requestData('/home', params); // 待还款首页
export const getBillLogList = (params) => requestData('/billLog', params); // 账单列表还款记录
export const getBillDetails = (params) => requestData('/billDetails', params); // 本期还款详情
export const getStayPayType = (params) => requestData('/payChannelList', params); // 还款渠道
export const playPay = (params) => requestData('/payBack', params); // 还款操作
export const getplayPayResult = (params) => requestData('/repaymentResult', params); // 查看还款结果
export const payCodeCreat = (params) => requestData('/getPayCode', params); // 生成支付码
export const payInfo = (params) => requestData('/repaymentInfo', params); // 付款详情
export const payStatus = (params) => requestData('/payStatus', params); // 还款状态
// 复贷
export const submitOrder = (params) => requestData('/subOrder', params); // 提交订单 申请借款
export const submitOrderGetDetail = (params) => requestData('/productInfo', params); // 提交订单 获取详情
export const registPoint = (params) => requestData('/registPoint', params); // 注册埋点
export const loginPoint = (params) => requestData('/loginPoint', params); // 登陆埋点
export const bankPoint = (params) => requestData('/bankPoint', params); // 提现方式埋点
export const reloanPoint = (params) => requestData('/reloanPoint', params); // 复贷页埋点
export const payCodePoint = (params) => requestData('/payCodePoint', params); // 还款页埋点
export const payTips = (params) => requestData('/payTips', params); // 支付渠道提示文案
export const ticketList = (params) => requestData('/ticketList', params); // 优惠券列表
export const activationEmail = (params) => requestData('/activation', params); // 激活邮箱
export const getActivationEmailStatus = (params) => requestData('/activationStatus', params); // 获取激活邮箱状态
